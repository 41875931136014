import "./People.scss";
import React from "react";

export type TProps = {
  image?: string,
  name: string,
  about: string,
  links?: {
    icon: string,
    name: string,
    link: string,
  }[],
}

export default function People(props: TProps) {
  const links = React.useMemo(() => {
    if (!props.links || props.links.length === 0) {
      return null;
    }

    return (
      <div className="c-intro__links">
        {
          props.links.map((l, i) => (
            <a key={"link-" + i} className="c-intro__links-item" href={l.link} title={l.name} target="_blank" rel="noreferrer">
              <img src={l.icon} alt={l.name + " icon"} />
            </a>
          ))
        }
      </div>
    );
  }, [props.links]);

  return (
    <div className="c-intro">
      {props.image && (
        <div className="c-intro__left">
          <div className="c-intro__image">
            <img src={props.image} alt={props.name + " image"} />
          </div>
        </div>
      )}
      <div className="c-intro__right">
        <div className="c-intro__name">{props.name}</div>
        <div className="c-intro__about">{props.about}</div>
        {links}
      </div>
    </div>
  );
}
