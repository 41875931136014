import React from "react";
import ContentWrapper from "../components/ContentWrapper";
import PageHeader from "../components/PageHeader";
import dataTerms from "../data/terms.json";
import "./Privacy.scss";

export default function Terms() {
  return (
    <>
      <ContentWrapper>
        <div className="page-privacy">
          <PageHeader title={dataTerms.title} tag="Website" />
          <div className="page-privacy__content" dangerouslySetInnerHTML={{__html: dataTerms.content}} />
        </div>
      </ContentWrapper>
    </>
  );
}
