import "./NotFound.scss";
import {Link, useLocation} from "react-router-dom";

export default function NotFound() {
  const {pathname} = useLocation();

  return (
    <div className="page-404">
      <div className="page-404__inner">
        <div className="page-404__number">404</div>
        <div className="page-404__text">
          The requested URL {pathname} was not found on this server.
        </div>
        <div className="page-404__text">
          That's an error. <Link to="/">Click here</Link> to go home.
        </div>
      </div>
    </div>
  );
}
