import React from "react";
import "./ContentWrapper.scss";

export type TProps = React.PropsWithChildren & {
  fill?: boolean,
  size?: "small" | "medium" | "large",
  verticalCenter?: boolean,
}

export default function ContentWrapper(props: TProps = {
  fill: false,
  size: "large",
  verticalCenter: false,
}) {
  const classes = ["c-content-wrapper"];

  if (!props.fill) {
    classes.push("c-content-wrapper--boxed");
    classes.push("c-content-wrapper--" + (props.size ?? "large"));
  }

  if (props.verticalCenter) {
    classes.push("c-content-wrapper--v-center");
  }

  return (
    <div className={classes.join(" ")}>
      {props.children}
    </div>
  );
}
