import React from "react";
import "./Skills.scss";

export type TProps = {
  name?: string,
  skills: { [k: string]: number } | string[],
}

export default function Skills(props: TProps) {
  const name = React.useMemo(() => {
    if (props.name) {
      return (
        <div className="c-skills__name">{props.name}</div>
      );
    }

    return null;
  }, [props.name]);

  const skills = React.useMemo(() => {
    if (Array.isArray(props.skills)) {
      return props.skills.map((s, i) => (
        <div key={"skill-" + i} className="c-skills__skill">{s}</div>
      ));
    }

    // @ts-ignore
    const keys = Object.keys(props.skills).sort((a, b) => props.skills[b] - props.skills[a]);

    return keys.map((s, i) => {
      // @ts-ignore
      const percent = props.skills[s];

      return (
        <div key={"skill-" + i} className="c-skills__skill">
          {s}
          <div className="c-skills__percent">
            <div className="c-skills__percent-inner" style={{width: percent + "%"}}></div>
          </div>
        </div>
      )
    });
  }, [props.skills]);

  return (
    <div className="c-skills">
      {name}
      <div className="c-skills__list">
        {skills}
      </div>
    </div>
  );
}
