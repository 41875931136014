import React from "react";
import "./Content.scss";

export default function Content(props: React.PropsWithChildren) {
  return (
    <div className="c-content">
      {props.children}
    </div>
  )
}
