import React from "react";
import {createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, useMatches} from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import dataMain from "./data/index.json";
import Privacy from "./pages/Privacy";
import dataAbout from "./data/about.json";
import dataContact from "./data/contact.json";
import dataPrivacy from "./data/privacy.json";
import dataTerms from "./data/terms.json";
import Terms from "./pages/Terms";

function TitleChanger() {
  const matches = useMatches();

  React.useLayoutEffect(() => {
    const list = matches.filter(r => r.handle);
    let title = "";

    if (list.length > 0) {
      const item = list.pop();

      // @ts-ignore
      if (item && item.handle?.title) {
        // @ts-ignore
        title = item.handle.title;
      }
    }

    if (title.length > 0) {
      document.title = title + ' - ' + dataMain.title;
    } else {
      document.title = dataMain.title ?? "";
    }
  }, [matches]);

  return null;
}

function RootElement() {
  return (
    <>
      <Outlet/>
      <TitleChanger/>
    </>
  );
}



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootElement/>}>
      <Route element={<MainLayout/>}>
        <Route path="/" element={<Home/>} />
        <Route path="/about.html" element={<About/>} handle={{title: dataAbout.title}} />
        <Route path="/contact.html" element={<Contact/>} handle={{title: dataContact.title}} />
        <Route path="/privacy.html" element={<Privacy/>} handle={{title: dataPrivacy.title}} />
        <Route path="/terms.html" element={<Terms/>} handle={{title: dataTerms.title}} />
      </Route>
      <Route path="/index.html" element={<Navigate to="/" replace={true} />} />
      <Route path="*" element={<NotFound/>} handle={{title: "Not found"}} />
      {/*<Route path="*" element={<Navigate to="/404.html" replace={true} />} />*/}
    </Route>
  )
);

export default router;
