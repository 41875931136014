import "./Bio.scss";
import React, {useState} from "react";

export type TProps = {
  name: string,
  jobTitle?: string,
  introduction?: string,
  content?: string,
  detail?: { [k: string]: string | number }
}

export default function Bio(props: TProps) {
  const [showMore, setShowMore] = useState<boolean>(false);
  const canExpand = React.useMemo(() => !!props.introduction && !!props.content, [props]);

  const content = React.useMemo(() => {
    if ((!props.introduction && !!props.content) || (showMore && props.content)) {
      return (
        <div className="c-bio__introduction" dangerouslySetInnerHTML={{__html: props.content}} />
      );
    } else if (props.introduction) {
      return (
        <div className="c-bio__introduction" dangerouslySetInnerHTML={{__html: props.introduction}} />
      );
    }

    return null;
  }, [showMore, props]);

  return (
    <div className="c-bio">
      <div className="c-bio__name">{props.name}</div>
      {props.jobTitle && <div className="c-bio__title">{props.jobTitle}</div>}
      {content}
      {canExpand && (
        <div className="c-bio__more" onClick={() => setShowMore(!showMore)}>
          {showMore ? "Read less" : "Read more"}
        </div>
      )}
    </div>
  );
}
