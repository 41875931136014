import "./MainLayout.scss";
import React from "react";
import {Outlet, useLocation} from "react-router-dom";
import Content from "../components/Content";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

export default function MainLayout() {
  const [showSidebar, setShowSidebar] = React.useState<boolean>(false);
  const location = useLocation();

  const classes = React.useMemo(() => {
    const result = ["layout-main"];

    if (showSidebar) {
      result.push("layout-main--with-sidebar");
    }

    return result;
  }, [showSidebar]);

  React.useEffect(() => {
    window.scrollTo({top: 0, behavior: "smooth"});
  }, [location]);

  return (
    <div className={classes.join(" ")}>
      <Sidebar onItemClick={() => setShowSidebar(false)}/>
      <Content>
        <Navbar onMenuClick={() => setShowSidebar(!showSidebar)}/>
        <Outlet/>
      </Content>
    </div>
  );
}
