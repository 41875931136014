import React from "react";
import Bio from "../components/Bio";
import ContentWrapper from "../components/ContentWrapper";
import PageHeader from "../components/PageHeader";
import Skills from "../components/Skills";
import dataHome from "../data/index.json";
import dataAbout from "../data/about.json";
import "./About.scss";

export default function About() {
  const skills = React.useMemo(() => {
    const names = Object.keys(dataAbout.skills);
    const result = [];

    for (let i = 0; i < names.length; i++) {
      result.push(
        // @ts-ignore
        <Skills key={"skills" + i} skills={dataAbout.skills[names[i]]} name={names[i]} />
      );
    }

    return result;
  }, []);

  return (
    <>
      <ContentWrapper>
        <div className="page-about">
          <PageHeader title={dataAbout.title} tag="Introduction" />
          <img
            className="page-about__cover"
            src="/images/about.jpg"
            alt="Sang Lu at Tam Thanh Community Art Village (Tam Ky city, Quang Nam province, Viet Nam)"
          />
          <Bio
            name={dataAbout.bio.name}
            jobTitle={dataAbout.bio.jobTitle}
            introduction={dataHome.content}
            content={dataAbout.content}
          />
          {skills}
        </div>
      </ContentWrapper>
    </>
  );
}
