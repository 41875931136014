import React from "react";
import ContentWrapper from "../components/ContentWrapper";
import PageHeader from "../components/PageHeader";
import dataPrivacy from "../data/privacy.json";
import "./Privacy.scss";

export default function Privacy() {
  return (
    <>
      <ContentWrapper>
        <div className="page-privacy">
          <PageHeader title={dataPrivacy.title} tag="Website" />
          <div className="page-privacy__content" dangerouslySetInnerHTML={{__html: dataPrivacy.content}} />
        </div>
      </ContentWrapper>
    </>
  );
}
