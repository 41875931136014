import ContentWrapper from "../components/ContentWrapper";
import People from "../components/People";
import dataAbout from "../data/about.json";
import dataContact from "../data/contact.json";
import "./Home.scss";

export default function Home() {
  return (
    <ContentWrapper size="medium" verticalCenter={true}>
      <div className="page-home">
        <People
          image="/images/avatar.jpg"
          name={dataAbout.bio.name}
          about={dataAbout.bio.slogan}
          links={[
            {name: "Telegram", link: dataContact.socials.telegram, icon: "/images/logo-telegram.svg"},
            {name: "Email", link: dataContact.email, icon: "/images/icon-envelope.svg"},
            {name: "Facebook", link: dataContact.socials.facebook, icon: "/images/logo-facebook.svg"},
            {name: "LinkedIn", link: dataContact.socials.linkedin, icon: "/images/logo-linkedin.svg"},
          ]}
        />
      </div>
    </ContentWrapper>
  );
}
