import "./PageHeader.scss";

export type TProps = {
  tag?: string,
  title: string,
}

export default function PageHeader(props: TProps) {
  return (
    <div className="c-page-header">
      {props.tag && <div className="c-page-header__tag">{props.tag}</div>}
      <h1 className="c-page-header__title">{props.title}</h1>
    </div>
  );
}
